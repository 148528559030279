import React from "react";
import { Link } from "react-router-dom";

function SectionMargin2() {
  return (
    <div class="section margin">
      <div class="base-container w-container">
        <div class="about-content-wrapper-2">
          <div class="about-image-left">
            <div class="bgr-absolute-2"></div>
          </div>
          <div class="about-description-right">
            <h2 className="trabajos-titulo">Fácil de Contactar</h2>
            <p class="paragraph-with-margin">
              Con nuestro servicio premium, puedes agendar tu cita a través de
              WhatsApp de manera rápida y sencilla. Nuestro equipo responde con
              prontitud para coordinar la instalación o actualización en el
              lugar que prefieras, ya sea en tu hogar u oficina. Disfruta de la
              comodidad y atención personalizada sin necesidad de salir de casa
            </p>
            <div className="trabajos-titulo">
              <a
                href="https://wa.me/+525629834261"
                class="primary-button w-button"
              >
                Agenda tu Cita
              </a>
              <Link
                to="/preguntas"
                class="secondary-button black-text w-button"
              >
                Preguntas Frecuentes
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionMargin2;
